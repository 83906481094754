// import { Switch } from "antd";
// import { actions, selectors } from "features";
// import { useDispatch, useSelector } from "react-redux";
// import { useTranslator, useTheme } from "hooks";

export function ModeSwitch() {
  // const tx = useTranslator();
  // const dispatch = useDispatch();
  // const theme = useTheme();

  return null;

  // return (
  //   <Switch
  //     checked={["dark", "outrun"].includes(theme)}
  //     checkedChildren={
  //       theme === "outrun" ? (
  //         <div className="perfectly-centered">
  //           <OutrunEmoji /> {tx("OUTRUN")}
  //         </div>
  //       ) : (
  //         `🌙 ${tx("DARK")}`
  //       )
  //     }
  //     unCheckedChildren={`🔆 ${tx("LIGHT")}`}
  //     onClick={() => dispatch(actions.themeToggled())}
  //   />
  // );
}
